import { signup_switch } from './signup'

// подгрузка страницы
$(document).on('turbolinks:load', function(event) {
    // modal init
    $('#modal-signup').modal({
        onOpenStart: function(){
            signup_switch();   
        }
    });
    //  modal trigger (need to reconf with many modals)
    $('.modal-trigger').on('click', function() {
        $('#modal-signup').modal('open');
    })
    
    // init nav mobile menu
    $('#nav-mobile-action').on('click', function(){
        nav_menu();
    })
    // change orientation 
    $(window).on('orientationchange', function() {
        $(window).one('resize', function() {
            nav_orient();
            
        });
    });
    // dropdown menu
    $(".dropdown-trigger").dropdown({
        constrainWidth: false
    });
    // tooltips
    $('.tooltipped').tooltip();

    //init sliders with single img
    $('.index-slider-elem').carousel({ 
        duration: 100,
        fullWidth: true,
        indicators: true
    });

    // init how sliders
    $('.how-slider-elem').carousel({ 
        duration: 100,
        fullWidth: true,
        indicators: true
    }).height(450);

    // init sliders with two imgs
    $('.clients-reviews').carousel({ 
        duration: 100,
        fullWidth: true,
        indicators: false, 
        numVisible: 2, 
    }).height(310);

    // init material img
    $('.materialboxed').materialbox();

    // problem click
    $('.problem-item').on('click', function(elem){
        problem_act(this);
    })

    slider_control();
});

// slider control
function slider_control(){
    $('.start-slider-control').on('click', function(){
        var instance = M.Carousel.getInstance($(this).parent());
        instance.prev();
    });

    $('.end-slider-control').on('click', function(){
        var instance = M.Carousel.getInstance($(this).parent());
        instance.next();
    });
}

// open/close nav mobile menu
function nav_menu(){
    var hh = $(document).height()
    var elem = $('#nav-mobile-menu');
    var hh_screen = $(window).height();
    var hh = elem.css('height');
    if(hh == '0px'){
        elem.css('height', hh_screen - 80 + 'px');
        $('body').css('overflow-y', 'hidden');
    } else {
        elem.css('height', '0px');
        $('body').css('overflow-y', 'auto');
    }
}

// close nav orient change
function nav_orient(){
    $('#nav-mobile-menu').css('height', '0px');
    $('body').css('overflow-y', 'auto');
}

// problem action
function problem_act(elem){
    $('.problems-list').children('.problem-item-open').each(function(){
        $(this).removeClass('problem-item-open');
        $(this).addClass('problem-item-close');
    })
    $(elem).removeClass('problem-item-close');
    $(elem).addClass('problem-item-open');
    var idx = $(elem).attr('data-index');
    console.log(idx);
    $(".problems-content").each(function(){
        $(this).removeClass('problems-content-show')
        $(this).addClass('problems-content-hide')
    })
    $(".problems-content[data-content='" + idx + "']").addClass('problems-content-show');
}