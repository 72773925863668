// signup module

// signup swtich
var signup_switch_variants = `
    <h5>Выберите приемлемый вариант регистрации в системе.</h5>
    <form action="#">
        <p class='signup-variant' data-variant='manager'>
            <label>
                <input class="with-gap" name="group1" type="radio" />
                <span>Регистрация через менеджера</span>
            </label>
        </p>
        <p class='signup-variant' data-variant='demo'>
            <label>
                <input class="with-gap" name="group1" type="radio" />
                <span>Демонстрационной доступ</span>
            </label>
        </p>
        <p class='signup-variant' data-variant='tmppay'>
            <label>
                <input class="with-gap" name="group1" type="radio" />
                <span>Платный временный доступ</span>
            </label>
        </p>
    </form>
    <div id='signup-detail'>
    </div>
`

// signup switch details
var signup_detail = {
    manager: {
        text: `
            <h5>Для получения доступа к сервису необходимо:</h5>
            <ol class='decimal'>
                <li>На следующем шаге оставить контактные данные в форме регистрации</li>
                <li>В рабочее время в течение 15 минут менеджер перезвонит Вам </li>
            </ol>
        `,
        button: `
            <p class="show-regform waves-effect waves-green btn-flat">Перейти к регистрации</p>
        `
    },
    demo: {
        text: `
            <h5>Доступ в сервис в режиме демонстрации.</h5>
            <p>Доступ в сервис в режиме демонстрации интерфейса и возможностей системы.</p>
        `,
        button: `
            <p class="show-regform waves-effect waves-green btn-flat">Перейти к регистрации</p>
        `
    },
    tmppay: {
        text: `
            <h5>Платный временный доступ.</h5>
            <p>
                В рамках данного варианта будет предоставлен платный доступ в сервис а 3 дня с ограничением 
                колчества адресов в маршруте до 50. Стоимость доступа 3000 рублей.
            </p>
        `,
        button: `
            <p class="show-regform waves-effect waves-green btn-flat">Оплатить</p>
        `
    }
}

// signup switch
export function signup_switch(){
    $('#signup-content').html(signup_switch_variants);
    $('#signup-footer').html(``);
    $('.signup-variant').on('click', function(){
        signup_variant($(this).attr('data-variant'));
    })
}

// signup variant
export function signup_variant(variant){
    var text = signup_detail[variant].text;
    var button = signup_detail[variant].button;
    $('#signup-detail').html(text);
    $('#signup-footer').html(button);
    $('.show-regform').on('click', function(){
        show_regform(variant)
        $('#signup-position').formSelect();
    })
    
}

// форма регистрации
function show_regform(variant){
    var regfrom = `
        <div>
            <div class="input-field">
                <input id="signup-email" type="text" class="validate">
                <label for="signup-email">E-mail:</label>
            </div>
            <div class="input-field">
                <input id="signup-phone" type="text" class="validate">
                <label for="signup-phone">Телефон:</label>
            </div>
            <div class="input-field">
                <select id="signup-position">
                    <option value="" disabled selected>Выберите должность</option>
                    <option value="chief">Руководитель</option>
                    <option value="logist">Логист</option>
                    <option value="it-spec">IT-специалист</option>
                    <option value="driver">Водитель</option>
                    <option value="other">Другое</option>
                </select>
                <label>Должность:</label>
            </div>
        </div>
    `
    $('#signup-content').html(regfrom);
    $('#signup-footer').html(`
        <p class="modal-close waves-effect waves-green btn-flat">Отмена</p>
        <p class="start-reg waves-effect waves-green btn-flat">Зарегистрироваться</p>
    `);
    $('.start-reg').on('click', function(){
        ajax_query(variant);
    })
}

// register query
function ajax_query(variant){
    var email = $('#signup-email').val();
    var phone = $('#signup-phone').val();
    var position = $('#signup-position').val();
    console.log(variant, email, phone, position);
}